import React, {useState, useEffect} from 'react';
import "./style.styl";

export default function WebsiteDevelopmentPage(props) {
    return (
        <div className="container draft-view">
            <h1>Website development</h1>
           <p className="text-muted">By contacting our web development agency, you get a reliable partner that provides a full range of web services for the development and maintenance of your site at a reasonable price. We are here for you and are devoted to building the best website for your business.</p>
           <p className="text-muted"></p>
           <h2>The pain of an underperforming website</h2>
           <p className="text-muted">Your website represents your brand. And a site that’s slow, unreliable, or hard to use is a website that customers and prospects will avoid. Internal teams also suffer when small website changes are agonizingly slow, and big changes are out of the question.&nbsp;</p>
           <p className="text-muted">Yet starting a web development project feels daunting, so businesses often feel stuck with the status quo, risking missed opportunities and competitive disadvantage.</p>
           <p className="text-muted">With our years of experience developing upon the top CMS platforms and a well-honed project management process, Wescale builds high-performing sites across a wide range of industries. We’ve overcome all kinds of challenges for our clients, helping achieve some exceptional results in the process.</p>
           <p className="text-muted"></p>
           <h2>How much does Website Development Cost?</h2>
           <p className="text-muted">The cost of website development varies depending on the complexity of the task. In general, the cost of creating a simple site starts from $1000 and increases depending on the features required and complexity.</p>
           <p className="text-muted">We strive to make a unique offer based on your needs and budget. A simple website can be finished in two weeks, while large projects naturally take more time.</p>
           <p className="text-muted">We are here for you and are devoted to making your vision come to life. Regardless of the size of the project, we will establish with you a schedule of stages that maximize results in the shortest possible time.</p>
           <p className="text-muted"></p>
           <h2>What to look for in a Website Developer</h2>
           <p className="text-muted">If you’re not a technology person, it’s not easy to choose a technology partner to develop your website. Fortunately, there are qualities you can look for without reviewing a single line of code.</p>
           <p className="text-muted"></p>
           <h5>A genuine interest in your business needs.</h5>
           <p className="text-muted">The newest and shiniest solution isn’t always the most appropriate. Taking an interest in your business isn’t just polite – it’s the only way for your agency to make sure the solution fits your true requirements.</p>
           <p className="text-muted"></p>
           <h5>Rigorous project management.</h5>
           <p className="text-muted">Your website agency must manage time and budget as well as the work quality. Wescale has the tools, experience, and skills to keep the project on the rails and push the boundaries of possibility.</p>
           <p className="text-muted"></p>
           <h5>The right range of partnerships.</h5>
           <p className="text-muted">A combination of breadth and depth is important. Technology platforms have different strengths and capabilities, and platform-specific expertise is highly valuable. But if your agency only knows one platform, guess what they’re going to recommend for you?</p>
           <p className="text-muted"><br /></p>
           <h2 >Website development process</h2><p  className="text-muted">The website development process involves multiple steps, and each Wescale specialist is practiced and experienced in each of them. In order to focus on more important items and not take advantage of your budget, if we feel the need, we may recommend changes to your plan</p>
           <p className="text-muted"></p>
           <h5>1. Collection and analysis of information</h5>
           <p className="text-muted">At this stage, we discuss the requirements of the site based on your needs and create a draft of what the final site will look like. Based on this information, we will calculate the approximate time needed and cost. We offer this consultation for free in order to provide you with the information you need to make a decision.</p>
           <p className="text-muted"></p>
           <h5>2. Research, UX, prototyping, and writing specification</h5>
           <p  className="text-muted">At this stage, we provide research on your business and your target audience to understand their motive and behavior with Wescale digital marketing specialists.&nbsp;<span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">After analyze we develop a prototype of the site – a visual and schematic representation of the location of all elements on each page including navigation, categories, subcategories, and their sequence. In the end, you will have prototypes of all the pages of your site and a detailed technical task list for the development of the site.</span></p>
           <p className="text-muted"></p>
           <h5>3. Creating the UI and website design</h5>
           <p className="text-muted">Based on the UX analysis list, the web designer will draw all the elements of the site: buttons, banners, forms, and other graphic elements. In other words, the prototype, which was created in the previous stage of development, gets an aesthetically pleasing facade with your choice of color palate. We can also work with an already established corporate style to match the company’s image if needed. The finished layout is then sent to you for review. All necessary adjustments will be made until we have your complete satisfaction.</p>
           <p className="text-muted"></p>
           <h5>4. Website Development</h5>
           <p  className="text-muted">After approval, our web developers will program the code that will implement the logic of the website, the functionality, the connected content management system.&nbsp;<span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">Our web developers consult with SEO specialists to make your website SEO-friendly. Sometimes sites can be used without a CMS (e.g. landings), so the site is simply imposed on HTML, CSS, JS. Our web developers are able to conduct all this work in parallel, which significantly speeds up the development time.</span></p>
           <p className="text-muted"></p>
           <h5>5. Final testing and launch</h5>
           <p className="text-muted">Despite the fact that testing occurs at each stage of the project, we perform final testing at the end of the website development phase. Our testers carefully check the website on all devices and in all browsers to identify if any errors occur. These are then sent to our developers to remedy. Once all testing tasks are completed, the site is ready for the official launch. After the site is launched, our work is not done. We will monitor the site for one month to help with any issues.</p>
           <p className="text-muted"></p>
           <h5>6. Website support</h5>
           <p  className="text-muted">Your ideas and approval are discussed at each stage until your vision is executed. Our business-goals oriented mentality guides our web agency to ensure satisfaction for you. There are many opportunities to get more from your site with us as we will carry out all its support needs and updates. In addition, over time, the site can benefit from changes in design that will keep its style relevant to the rapidly changing trends in the world of web development.</p>
           <p  className="text-muted"></p>
           <p  className="text-muted">Website development is our main expertise, so if you’re looking for a complete and agile team of web specialists to get your website redesigned and developed into a clean coded website, get a proposal from our Project Manager!<br /></p>

        </div>

    );
}
